import React, { Component } from 'react';
import { firestore } from '../../firebase';
import moment from 'moment';

class SingleCampaignProcess extends Component {
    constructor() {
        super();
        this.state = {
            process: []
        }
    }
    componentDidMount() {
        firestore.collection('campaigns').doc(this.props.id).collection('process').onSnapshot(snap => {
            const process = snap.docs.map(doc => doc.data());
            this.setState({ process })
        })
    }
    handleSubmit(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const description = this.description.value;
        const step = this.step.value;
        const term = this.term.value;
        const projectRef = firestore.collection('campaigns').doc(this.props.id);
        if(description && step && term) {
            const processId = `process-${timestamp}`;
            const process = {
                description,
                timestamp,
                id: processId,
                step,
                term
            }
            projectRef.collection('process').doc(processId).set(process).then(() => {
                alert('Paso agregado')
                this.addForm.reset();
            }).catch((error) => {
                alert('Ha ocurrido un error al agregar paso: ' + error)
            });
        } else {
            alert('Completa todos los campos')
        }
    }
    handleDelete(id) {
        firestore.collection('campaigns').doc(this.props.id).collection('process').doc(id).delete().then(() => {
            alert('Paso eliminado')
        }).catch(error => {
            alert('error al eliminar paso: ' + error)
        })
    }
    renderItem(key,list) {
        const item = list[key];
        return (
            <tr key={key}>
                <td>{item.step}</td>
                <td>{item.description}</td>
                <td>{item.term}</td>
                <td><button onClick={() => this.handleDelete(item.id)}>Eliminar</button></td>
            </tr>
        )
    }
    validator(list) {
        if(list) {
            return (Object.keys(list).map(key => this.renderItem(key,list)))
        }
    }
    render() {
        return ( 
            <div className="single-campaign__action-plan">
                <h1>Proceso de campaña</h1>
                <form ref={input => this.addForm = input} onSubmit={(e) => this.handleSubmit(e)}>
                <div className="single-campaign__input-container">
                        <label>Paso</label>
                        <input placeholder="Paso " ref={(input) => this.step = input} type="text"/>
                    </div>
                    <div className="single-campaign__input-container">
                        <label>Descripción</label>
                        <input placeholder="Descripción " ref={(input) => this.description = input} type="text"/>
                    </div>
                    <div className="single-campaign__input-container">
                        <label>Término</label>
                        <input placeholder="Término" ref={(input) => this.term = input} type="text"/>
                    </div>
                    <div className="single-campaign__input-container">
                        <button type="submit">Agregar</button>
                    </div>
                </form>
                <table>
                    <thead>
                        <tr>
                            <th>Paso</th>
                            <th>Descripción</th>
                            <th>Término</th>
                        </tr>
                    </thead>
                    <tbody>{this.validator(this.state.process)}</tbody>
                </table>
            </div>
        )
    }
}

export default SingleCampaignProcess;
import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import PropTypes from 'prop-types';

import Dashboard from './dashboard/Dashboard';
import Projects from './projects/Projects';
import SingleProject from './single-project/SingleProject';
import Campaigns from './campaigns/Campaigns';
import SingleCampaign from './single-campaign/SingleCampaign';

class App extends Component {
    constructor() {
        super();
        this.state = {
            profile: {}
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }
    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                const userEmail = user.email;
                firestore.collection('users').where('email', '==', userEmail).onSnapshot((snap) => {
                    const profile = snap.docs[0].data();
                    if (profile.rol === 'admin') {
                        this.setState({ profile })
                    } else {
                        window.location = 'https://stoq.mx'
                    }
                });
            } else {
                this.context.router.history.push('/log-in');
            }
        });
    }
    render() {
        return (
            <div className="app">
                <nav>
                    <Link to="/">Dashboard</Link>
                    <Link to="/projects">Proyectos</Link>
                    <Link to="/campaigns">Campañas</Link>
                    <Link to="/users">Usuarios</Link>
                    <Link to="/investments">Inversiones</Link>
                    <Link to="/gains">Ganancias</Link>
                </nav>
                <main>
                    <Switch>
                        <Route path="/" exact={true} component={Dashboard} />
                        <Route path="/projects" exact={true} component={Projects} />
                        <Route path="/projects/:projectId" exact={true} component={SingleProject} />
                        <Route path="/campaigns" exact={true} component={Campaigns} />
                        <Route path="/campaigns/:campaignId" exact={true} component={SingleCampaign} />
                    </Switch>
                </main>
            </div>
        )
    }
}

export default App;
import React, { Component } from 'react';
import { firestore, storage } from '../../firebase';
import moment from 'moment';

class SingleProjectFiles extends Component {
    constructor() {
        super();
        this.state = {
            files: []
        }
    }
    componentDidMount() {
        firestore.collection('projects').doc(this.props.id).collection('files').onSnapshot(snap => {
            const files = snap.docs.map(doc => doc.data());
            this.setState({ files })
        })
    }
    handleSubmit(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const title = this.title.value;
        const file = this.file.files[0];
        const projectRef = firestore.collection('projects').doc(this.props.id);
        if(title && file) {
            const ext = file.name.split('.').pop();
            const fileId = `file-${timestamp}`;
            const fileName = `${fileId}.${ext}`;
            const uploadFile = storage.ref('projects/' + this.props.id+ '/files').child(fileName).put(file);

            uploadFile.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({
                    bytes: progress
                });
            }, (error) => {
                alert('Ha ocurrido un error: ' + error);
                this.setState({
                    bytes: undefined
                });
            }, () => {
                uploadFile.snapshot.ref.getDownloadURL().then((url) => {
                    const fileId = `file-${timestamp}`;
                    const file = {
                        title: title,
                        timestamp: timestamp,
                        id: fileId,
                        file: url
                    }
                    projectRef.collection('files').doc(fileId).set(file).then(() => {
                        alert('Archivo agregada')
                    }).catch((error) => {
                        alert('Ha ocurrido un error al agregar archivo: ' + error)
                    });
                })
            })
        } else {
            alert('Completa todos los campos')
        }
    }
    handleDelete(id) {
        firestore.collection('projects').doc(this.props.id).collection('files').doc(id).delete().then(() => {
            alert('archivo eliminada')
        }).catch(error => {
            alert('error al eliminar archivo: ' + error)
        })
    }
    renderItem(key,list) {
        const item = list[key];
        return (
            <li key={key}>
                <a rel="noopener noreferrer" target="_blank" href={item.file}>{item.title}</a>
                <button onClick={() => this.handleDelete(item.id)}>Eliminar</button>
            </li>
        )
    }
    validator(list) {
        if(list) {
            return (Object.keys(list).map(key => this.renderItem(key,list)))
        }
    }
    render() {
        return ( 
            <div className="single-project__images">
                <h1>Archivos del proyecto</h1>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="single-project__input-container">
                        <label>Título</label>
                        <input placeholder="Título" ref={(input) => this.title = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <label>Archivo</label>
                        <input ref={(input) => this.file = input} type="file"/>
                    </div>
                    <div className="single-project__input-container">
                        <button type="submit">Agregar</button>
                    </div>
                </form>
                <ul>
                    {this.validator(this.state.files)}
                </ul>
            </div>
        )
    }
}

export default SingleProjectFiles;
import React, { Component } from 'react';
import { firestore, storage } from '../../firebase';
import SingleProjectImages from './SIngleProjectImages';
import SingleProjectFrames from './SIngleProjectFrames';
import SingleProjectHighlights from './SingleprojectHighlights';
import SingleProjectFiles from './SingleProjectFiles';

class SingleProject extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            project: {}
        }
    }
    componentDidMount() {
        firestore.collection('projects').doc(this.props.match.params.projectId).onSnapshot(snap => {
            this.setState({
                project: snap.data()
            });
        });
    }
    handleChange() {
        const name = this.name.value;
        const shortDescription = this.shortDescription.value;
        const location = this.location.value;
        const category = this.category.value;
        const webPage = this.webPage.value;
        const facebook = this.facebook.value;
        const twitter = this.twitter.value;
        const instagram = this.instagram.value;
        const description = this.description.value;
        const managementInformation = this.managementInformation.value;
        firestore.collection('projects').doc(this.props.match.params.projectId).update({
            name,
            shortDescription,
            location,
            category,
            webPage,
            facebook,
            twitter,
            instagram,
            description,
            managementInformation
        })
    }
    handleLogo(e) {
        e.preventDefault();
        const file = this.logo.files[0];
        if(file) {
            const ext = file.name.split('.').pop();
            const fileName = `${this.props.match.params.projectId}.${ext}`;
            const uploadFile = storage.ref('logos').child(fileName).put(file);

            uploadFile.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({
                    bytes: progress
                });
            }, (error) => {
                alert('Ha ocurrido un error: ' + error);
                this.setState({
                    bytes: undefined
                });
            }, () => {
                uploadFile.snapshot.ref.getDownloadURL().then((url) => {
                    firestore.collection('projects').doc(this.props.match.params.projectId).update({ 'logo' : url })
                }).then(() => {
                    alert('Logo establecido')
                }).catch(error => {
                    console.log(error);
                    alert('Error al subir logo: ' + error);
                })
            })
        } else {
            alert('Selecciona una imagen')
        }
    }
    render() {
        const {project} = this.state;
        const projectId = this.props.match.params.projectId;
        return (
            <div className="single-project">
                <div className="single-project__logo">
                    <form onSubmit={e => this.handleLogo(e)}>
                        <div className="single-project__input-container">
                            <label>Logo</label>
                            <input ref={input => this.logo = input} type="file" />
                        </div>
                        <div className="single-project__input-container">
                            <button type="submit">Poner logo</button>
                        </div>
                    </form>
                    <picture>
                        <img src={project.logo} alt={project.name}/>
                    </picture>
                </div>
                <div className="single-project__details">
                    <div className="single-project__input-container">
                        <label>Nombre</label>
                        <input onChange={this.handleChange} defaultValue={project.name} ref={input => this.name = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <label>Descripción Corta</label>
                        <input onChange={this.handleChange} defaultValue={project.shortDescription} ref={input => this.shortDescription = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <label>Ubicación</label>
                        <input onChange={this.handleChange} defaultValue={project.location} ref={input => this.location = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <label>Categoría</label>
                        <input onChange={this.handleChange} defaultValue={project.category} ref={input => this.category = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <label>Página Web</label>
                        <input onChange={this.handleChange} defaultValue={project.webPage} ref={input => this.webPage = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <label>FaceBook</label>
                        <input onChange={this.handleChange} defaultValue={project.facebook} ref={input => this.facebook = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <label>Twitter</label>
                        <input onChange={this.handleChange} defaultValue={project.twitter} ref={input => this.twitter = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <label>Instagram</label>
                        <input onChange={this.handleChange} defaultValue={project.instagram} ref={input => this.instagram = input} type="text"/>
                    </div>
                </div>
                <div className="single-project__description">
                    <div className="single-project__input-container">
                        <label>Descripción Completa</label>
                        <textarea onChange={this.handleChange} value={project.description} ref={input => this.description = input}/>
                    </div>
                </div>
                <div className="single-project__management">
                    <div className="single-project__input-container">
                        <label>Información Administrativa</label>
                        <textarea onChange={this.handleChange} value={project.managementInformation} ref={input => this.managementInformation = input}/>
                    </div>
                </div>
                {projectId ? <SingleProjectImages id={projectId} /> : ''}
                {projectId ? <SingleProjectFrames id={projectId} /> : ''}
                {projectId ? <SingleProjectHighlights id={projectId} /> : ''}
                {projectId ? <SingleProjectFiles id={projectId} /> : ''}
            </div>
        )
    }
}

export default SingleProject;
import * as firebase from 'firebase';

firebase.initializeApp({
    apiKey: "AIzaSyDApREpAroCsFpQ2h4UIOuEOEvt-AYTfds",
    authDomain: "new-stoq.firebaseapp.com",
    databaseURL: "https://new-stoq.firebaseio.com",
    projectId: "new-stoq",
    storageBucket: "new-stoq.appspot.com",
    messagingSenderId: "50735850590"
});

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
const settings = { /* your settings... */
    timestampsInSnapshots: true
};
firestore.settings(settings);
import React, { Component } from 'react';
import { auth, firestore } from '../firebase';
import PropTypes from 'prop-types';

class LogIn extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if(user) {
                const userEmail = user.email;
                firestore.collection('users').where('email', '==', userEmail).onSnapshot((snap) => {
                    const profile = snap.docs[0].data();
                    if(profile.rol === 'admin') {
                        this.context.router.history.push('/')
                    } else {
                        window.location = 'https://stoq.mx'
                    }
                });
            }
        });
    }
    handleSubmit(e) {
        e.preventDefault();

        const email = this.email.value;
        const password = this.password.value;

        const logIn = auth.signInWithEmailAndPassword(email,password);
        logIn.then(() => {
            firestore.collection('users').where('email', '==', email).onSnapshot((snap) => {
                const profile = snap.docs[0].data();
                if(profile.rol === 'admin') {
                    this.context.router.history.push('/')
                } else {
                    auth.signOut();
                    window.location = 'https://stoq.mx'
                }
            })
        }).catch((error) => {
            alert('Ha ocurrido un error al iniciar sesión: ' + error);
        })
    }
    render() {
        return (
            <div className="log-in">
                <form onSubmit={(e) => this.handleSubmit(e)} ref={(input) => this.logInForm = input}>
                    <h1>Inicia Sesión</h1>
                    <div className="log-in__input-container">
                        <label>Email</label>
                        <input placeholder="Email" ref={(input) => this.email = input} type="email"/>
                    </div>
                    <div className="log-in__input-container">
                        <label>Contraseña</label>
                        <input placeholder="Contraseña" ref={(input) => this.password = input} type="password" />
                    </div>
                    <div className="log-in__input-container">
                        <button type="submit">Iniciar Sesión</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default LogIn;
import React, { Component } from 'react';
import { firestore } from '../../firebase';
import moment from 'moment';
import numeral from 'numeral';

class SingleCampaignActionPlan extends Component {
    constructor() {
        super();
        this.state = {
            actionPlan: []
        }
    }
    componentDidMount() {
        firestore.collection('campaigns').doc(this.props.id).collection('actionPlan').onSnapshot(snap => {
            const actionPlan = snap.docs.map(doc => doc.data());
            this.setState({ actionPlan })
        })
    }
    handleSubmit(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const description = this.description.value;
        const percentage = Number(this.percentage.value);
        const projectRef = firestore.collection('campaigns').doc(this.props.id);
        if(description && percentage) {
            const actionPlanId = `actionPlan-${timestamp}`;
            const actionPlan = {
                description,
                timestamp,
                id: actionPlanId,
                percentage
            }
            projectRef.collection('actionPlan').doc(actionPlanId).set(actionPlan).then(() => {
                alert('Paso agregado')
                this.addForm.reset();
            }).catch((error) => {
                alert('Ha ocurrido un error al agregar Paso: ' + error)
            });
        } else {
            alert('Completa todos los campos')
        }
    }
    handleDelete(id) {
        firestore.collection('campaigns').doc(this.props.id).collection('actionPlan').doc(id).delete().then(() => {
            alert('Paso eliminado')
        }).catch(error => {
            alert('error al eliminar Paso: ' + error)
        })
    }
    renderItem(key,list) {
        const item = list[key];
        return (
            <tr key={key}>
                <td>{item.description}</td>
                <td>{numeral(item.percentage/100).format('0.00%')}</td>
                <td><button onClick={() => this.handleDelete(item.id)}>Eliminar</button></td>
            </tr>
        )
    }
    validator(list) {
        if(list) {
            return (Object.keys(list).map(key => this.renderItem(key,list)))
        }
    }
    render() {
        return ( 
            <div className="single-campaign__action-plan">
                <h1>Plan de acción de campaña</h1>
                <form ref={input => this.addForm = input} onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="single-campaign__input-container">
                        <label>Descripción </label>
                        <input placeholder="Descripción " ref={(input) => this.description = input} type="text"/>
                    </div>
                    <div className="single-campaign__input-container">
                        <label>Porcentaje (0 - 100)</label>
                        <input placeholder="Porcentaje" ref={(input) => this.percentage = input} type="number"/>
                    </div>
                    <div className="single-campaign__input-container">
                        <button type="submit">Agregar</button>
                    </div>
                </form>
                <table>
                    <thead>
                        <tr>
                            <th>Descripción</th>
                            <th>Porcentaje</th>
                        </tr>
                    </thead>
                    <tbody>{this.validator(this.state.actionPlan)}</tbody>
                </table>
            </div>
        )
    }
}

export default SingleCampaignActionPlan;
import React, { Component } from 'react';
import { firestore } from '../../firebase';
import { Link } from 'react-router-dom';
import moment from 'moment';

class Projects extends Component {
    constructor() {
        super();
        this.state = {
            projects: []
        }
    }
    componentDidMount() {
        firestore.collection('projects').onSnapshot(snap => {
            const projects = snap.docs.map(doc => doc.data());
            this.setState({ projects })
        })
    }
    renderItem(key,list) {
        const item = list[key];
        return (
            <tr key={key}>
                <td><img src={item.logo} alt={item.name}/></td>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{moment(item.timestamp).format('DD-MM-YYYY hh:mm')}</td>
                <td><Link to={`/projects/${item.id}`}><i className="material-icons">arrow_forward_ios</i></Link></td>
            </tr>
        )
    }
    validator(list) {
        if(list) {
            return (
                Object.keys(list).map(key => this.renderItem(key,list))
            )
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const name = this.name.value;
        if(name) {
            const projectId = `project-${timestamp}`;
            const project = {
                name,
                timestamp,
                id: projectId
            }
            firestore.collection('projects').doc(projectId).set(project).then(() => {
                alert('Proyecto agregado')
                this.addForm.reset()
            }).catch(error => {
                alert('Error al agregar proyecto: ' + error)
            });
        }
    }
    render() {
        return (
            <div className="projects">
                <form ref={input => this.addForm = input} onSubmit={e => this.handleSubmit(e)}>
                    <div className="projects__input-container">
                        <label>Nombre del Proyecto</label>
                        <input ref={input => this.name = input} type="text"/>
                    </div>
                    <div className="projects__input-container">
                        <button type="submit">Crear</button>
                    </div>
                </form>
                <table>
                    <thead>
                        <tr>
                            <th>Logo</th>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Fecha de Creación</th>
                            <th>Ver Más</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.validator(this.state.projects)}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Projects;
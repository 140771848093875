import React, { Component } from 'react';
import { firestore, storage } from '../../firebase';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import SingleCampaignActionPlan from './SIngleCampaignActionPlan';
import SingleCampaignProcess from './SingleCampaignProcess';

class SingleCampaign extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            campaign: {}
        }
    }
    componentDidMount() {
        firestore.collection('campaigns').doc(this.props.match.params.campaignId).onSnapshot(snap => {
            this.setState({
                campaign: snap.data()
            });
        });
    }
    handleChange() {
        const status = this.status.value;
        const description = this.description.value;
        const comments = this.comments.value;
        firestore.collection('campaigns').doc(this.props.match.params.campaignId).update({
            status,
            description,
            comments
        });
    }
    handleFinancial(e) {
        e.preventDefault();
        const file = this.financialInformation.files[0];
        if(file) {
            const ext = file.name.split('.').pop();
            const fileName = `${this.props.match.params.campaignId}.${ext}`;
            const uploadFile = storage.ref('financial-information').child(fileName).put(file);

            uploadFile.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({
                    bytes: progress
                });
            }, (error) => {
                alert('Ha ocurrido un error: ' + error);
                this.setState({
                    bytes: undefined
                });
            }, () => {
                uploadFile.snapshot.ref.getDownloadURL().then((url) => {
                    firestore.collection('campaigns').doc(this.props.match.params.campaignId).update({ 'financialInformation' : url })
                }).then(() => {
                    alert('Archivo subido')
                }).catch(error => {
                    console.log(error);
                    alert('Error al subir archivo: ' + error);
                })
            })
        } else {
            alert('Selecciona un archivo')
        }
    }
    render() {
        const {campaign} = this.state;
        const campaignId = this.props.match.params.campaignId;
        return (
            <div className="single-campaign">
                <ul className="single-campaign__details">
                    <li>
                        <b>ID: </b>
                        <span>{campaign.id}</span>
                    </li>
                    <li>
                        <b>Proyecto / Empresa: </b>
                        <Link to={`/projects/${campaign.projectId}`}>{campaign.projectName}</Link>
                    </li>
                    <li>
                        <b>Objetivo: </b>
                        <span>{numeral(campaign.goal).format('$0,0.00')}</span>
                    </li>
                    <li>
                        <b>Recolectado: </b>
                        <span>{numeral(campaign.collected).format('$0,0.00')}</span>
                    </li>
                    <li>
                        <b>Porcentaje a Cambio: </b>
                        <span>{numeral(campaign.percentage/100).format('0.000%')}</span>
                    </li>
                    <li>
                        <b>Porcentaje Vendido: </b>
                        <span>{numeral(campaign.percentageSold/100).format('0.000%')}</span>
                    </li>
                </ul>
                <div className="single-campaign__modify">
                    <div className="single-campaign__input-container">
                        <label>Estatus</label>
                        <select onChange={this.handleChange} value={campaign.status} ref={input => this.status = input}>
                            <option value="pending">Pendiente por clompletar</option>
                            <option value="funding">Fondeando</option>
                            <option disabled={true} value="funded">Completado</option>
                        </select>
                    </div>
                    <div className="single-campaign__input-container">
                        <label>Descripción Corta de Campaña</label>
                        <textarea onChange={this.handleChange} value={campaign.description} ref={input => this.description = input} type="text"/>
                    </div>
                    <div className="single-campaign__input-container">
                        <label>Comentarios Internos de Campaña</label>
                        <textarea onChange={this.handleChange} value={campaign.comments} ref={input => this.comments = input} type="text"/>
                    </div>
                    <form onSubmit={e => this.handleFinancial(e)} className="single-campaign__input-container">
                        <label>Información Financiera de Campaña - {campaign.financialInformation ? <a target="_blank" rel="noopener noreferrer" href={campaign.financialInformation}>Descargar</a> : ''}</label>
                        <input ref={input => this.financialInformation = input} type="file"/>
                        <button type="submit">{campaign.financialInformation ? 'Cambiar' : 'Agregar'}</button>
                    </form>
                </div>
                {campaignId ? <SingleCampaignActionPlan id={campaignId} /> : ''}
                {campaignId ? <SingleCampaignProcess id={campaignId} /> : ''}
            </div>
        )
    }
}

export default SingleCampaign;
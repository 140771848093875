import React, { Component } from 'react';
import { firestore, storage } from '../../firebase';
import Compressor from 'compressorjs';
import moment from 'moment';

class SingleProjectImages extends Component {
    constructor() {
        super();
        this.state = {
            images: []
        }
    }
    componentDidMount() {
        firestore.collection('projects').doc(this.props.id).collection('images').onSnapshot(snap => {
            const images = snap.docs.map(doc => doc.data());
            this.setState({ images })
        })
    }
    handleSubmit(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const title = this.title.value;
        const file = this.file.files[0];
        const projectRef = firestore.collection('projects').doc(this.props.id);
        var self = this;
        if(title && file) {
            new Compressor(file, {
                quality: 0.6,
                success(result) {
                    const ext = result.name.split('.').pop();
                    const fileId = `file-${timestamp}`;
                    const fileName = `${fileId}.${ext}`;
                    const uploadFile = storage.ref('projects/' + self.props.id+ '/images').child(fileName).put(result);

                    uploadFile.on('state_changed', (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        self.setState({
                            bytes: progress
                        });
                    }, (error) => {
                        alert('Ha ocurrido un error: ' + error);
                        self.setState({
                            bytes: undefined
                        });
                    }, () => {
                        uploadFile.snapshot.ref.getDownloadURL().then((url) => {
                            const imageId = `image-${timestamp}`;
                            const image = {
                                title: title,
                                timestamp: timestamp,
                                id: imageId,
                                file: url
                            }
                            projectRef.collection('images').doc(imageId).set(image).then(() => {
                                alert('Imagen agregada')
                            }).catch((error) => {
                                alert('Ha ocurrido un error al agregar imagen: ' + error)
                            });
                        })
                    })
                },
                error(err) {
                    alert('Ocurrió un error al subir imagen: ' + err)
                }
            });
        } else {
            alert('Completa todos los campos')
        }
    }
    handleDelete(id) {
        firestore.collection('projects').doc(this.props.id).collection('images').doc(id).delete().then(() => {
            alert('Imagen eliminada')
        }).catch(error => {
            alert('error al eliminar imagen: ' + error)
        })
    }
    renderItem(key,list) {
        const item = list[key];
        return (
            <li key={key}>
                <img src={item.file} alt={item.title}/>
                <button onClick={() => this.handleDelete(item.id)}>Eliminar</button>
            </li>
        )
    }
    validator(list) {
        if(list) {
            return (Object.keys(list).map(key => this.renderItem(key,list)))
        }
    }
    render() {
        return ( 
            <div className="single-project__images">
                <h1>Imagenes del proyecto</h1>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="single-project__input-container">
                        <label>Título</label>
                        <input placeholder="Título" ref={(input) => this.title = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <label>Imagen</label>
                        <input ref={(input) => this.file = input} type="file"/>
                    </div>
                    <div className="single-project__input-container">
                        <button type="submit">Agregar</button>
                    </div>
                </form>
                <ul>
                    {this.validator(this.state.images)}
                </ul>
            </div>
        )
    }
}

export default SingleProjectImages;
import React, { Component } from 'react';
import { firestore } from '../../firebase';
import moment from 'moment';

class SingleProjectFrames extends Component {
    constructor() {
        super();
        this.state = {
            frames: []
        }
    }
    componentDidMount() {
        firestore.collection('projects').doc(this.props.id).collection('frames').onSnapshot(snap => {
            const frames = snap.docs.map(doc => doc.data());
            this.setState({ frames })
        })
    }
    handleSubmit(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const title = this.title.value;
        const link = this.link.value;
        const projectRef = firestore.collection('projects').doc(this.props.id);
        if(title && link) {
            const frameId = `frame-${timestamp}`;
            const frame = {
                title,
                timestamp,
                id: frameId,
                link
            }
            projectRef.collection('frames').doc(frameId).set(frame).then(() => {
                alert('Frame agregado')
                this.addForm.reset();
            }).catch((error) => {
                alert('Ha ocurrido un error al agregar frame: ' + error)
            });
        } else {
            alert('Completa todos los campos')
        }
    }
    handleDelete(id) {
        firestore.collection('projects').doc(this.props.id).collection('frames').doc(id).delete().then(() => {
            alert('Frame eliminado')
        }).catch(error => {
            alert('Error al eliminar frame: ' + error)
        })
    }
    renderItem(key,list) {
        const item = list[key];
        return (
            <li key={key}>
                <a href={item.link}>{item.title}</a>
                <button onClick={() => this.handleDelete(item.id)}>Eliminar</button>
            </li>
        )
    }
    validator(list) {
        if(list) {
            return (Object.keys(list).map(key => this.renderItem(key,list)))
        }
    }
    render() {
        return ( 
            <div className="single-project__images">
                <h1>Frames del proyecto</h1>
                <form ref={input => this.addForm = input} onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="single-project__input-container">
                        <label>Título</label>
                        <input placeholder="Título" ref={(input) => this.title = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <label>Link</label>
                        <input placeholder="Link" ref={(input) => this.link = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <button type="submit">Agregar</button>
                    </div>
                </form>
                <ul>
                    {this.validator(this.state.frames)}
                </ul>
            </div>
        )
    }
}

export default SingleProjectFrames;
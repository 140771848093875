import React, { Component } from 'react';
import { firestore } from '../../firebase';
import moment from 'moment';

class SingleProjectHighlights extends Component {
    constructor() {
        super();
        this.state = {
            highlights: []
        }
    }
    componentDidMount() {
        firestore.collection('projects').doc(this.props.id).collection('highlights').onSnapshot(snap => {
            const highlights = snap.docs.map(doc => doc.data());
            this.setState({ highlights })
        })
    }
    handleSubmit(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const title = this.title.value;
        const description = this.description.value;
        const projectRef = firestore.collection('projects').doc(this.props.id);
        if(title && description) {
            const highlightId = `highlight-${timestamp}`;
            const highlight = {
                title,
                timestamp,
                id: highlightId,
                description
            }
            projectRef.collection('highlights').doc(highlightId).set(highlight).then(() => {
                alert('Highlight agregado')
                this.addForm.reset();
            }).catch((error) => {
                alert('Ha ocurrido un error al agregar highlight: ' + error)
            });
        } else {
            alert('Completa todos los campos')
        }
    }
    handleDelete(id) {
        firestore.collection('projects').doc(this.props.id).collection('highlights').doc(id).delete().then(() => {
            alert('Highlight eliminado')
        }).catch(error => {
            alert('error al eliminar highlight: ' + error)
        })
    }
    renderItem(key,list) {
        const item = list[key];
        return (
            <li key={key}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <button onClick={() => this.handleDelete(item.id)}>Eliminar</button>
            </li>
        )
    }
    validator(list) {
        if(list) {
            return (Object.keys(list).map(key => this.renderItem(key,list)))
        }
    }
    render() {
        return ( 
            <div className="single-project__images">
                <h1>Highlights del proyecto</h1>
                <form ref={input => this.addForm = input} onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="single-project__input-container">
                        <label>Título</label>
                        <input placeholder="Título" ref={(input) => this.title = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <label>Descripción</label>
                        <input placeholder="Descripción" ref={(input) => this.description = input} type="text"/>
                    </div>
                    <div className="single-project__input-container">
                        <button type="submit">Agregar</button>
                    </div>
                </form>
                <ul className="highlight">
                    {this.validator(this.state.highlights)}
                </ul>
            </div>
        )
    }
}

export default SingleProjectHighlights;
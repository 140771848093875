import React, { Component } from 'react';
import { firestore } from '../../firebase';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import numeral from 'numeral';

class Campaigns extends Component {
    constructor() {
        super();
        this.state = {
            campaigns: [],
            projects: [],
            selectedProject: undefined
        }
    }
    componentDidMount() {
        firestore.collection('campaigns').onSnapshot(snap => {
            const campaigns = snap.docs.map(doc => doc.data());
            this.setState({ campaigns });
        });
        firestore.collection('projects').get().then(snap => {
            snap.docs.forEach(doc => {
                this.setState({
                    projects: [...this.state.projects, { label: doc.data().name, value: doc.data().id }]
                });
            });
        });
    }
    handleProject = (selectedProject) => {
        this.setState({ selectedProject })
    }
    renderItem(key, list) {
        const item = list[key];
        return (
            <tr key={key}>
                <td>{item.id}</td>
                <td><Link to={`/projects/${item.projectId}`}>{item.projectName}</Link></td>
                <td>{ item.status === 'pending' ? 'Pendiente de Completar' : item.status === 'funding' ? 'Fondeando' : item.status === 'funded' ? 'Campaña Completada' : '' }</td>
                <td>{numeral(item.percentage/100).format('0.000%')}</td>
                <td>{numeral(item.percentageSold/100).format('0.000%')}</td>
                <td>{numeral(item.goal).format('$0,0.00')}</td>
                <td>{numeral(item.collected).format('$0,0.00')}</td>
                <td><Link to={`/campaigns/${item.id}`}><i className="material-icons">arrow_forward_ios</i></Link></td>
            </tr>
        )
    }
    validator(list) {
        if(list) {
            return (Object.keys(list).map(key => this.renderItem(key,list)))
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const project = this.state.selectedProject;
        const goal = Number(this.goal.value);
        const percentage = Number(this.percentage.value);
        if(project && goal && percentage) {
            const campaignId = `campaign-${timestamp}`;
            const campaign = {
                projectId: project.value,
                projectName: project.label,
                goal,
                percentage,
                status: 'pending',
                timestamp,
                id: campaignId,
                percentageSold: 0,
                collected: 0
            }
            firestore.collection('campaigns').doc(campaignId).set(campaign).then(() => {
                alert('Campaña agregada');
                this.addForm.reset();
                this.setState({
                    selectedProject: undefined
                })
            }).catch(error => {
                alert('Error al agregar campaña: ' + error)
            });
        } else {
            alert('Completa todos los campos')
        }
    }
    render() {
        return (
            <div className="campaigns">
                <form ref={input => this.addForm = input} onSubmit={e => this.handleSubmit(e)}>
                    <div className="campaigns__input-container">
                        <label>Proyecto</label>
                        <Select
                            options={this.state.projects}
                            onChange={this.handleProject}
                            value={this.state.selectedProject}
                        />
                    </div>
                    <div className="campaigns__input-container">
                        <label>Objetivo</label>
                        <input ref={input => this.goal = input} type="number"/>
                    </div>
                    <div className="campaigns__input-container">
                        <label>Porcentaje A Cambio (0 - 100)</label>
                        <input step=".01" ref={input => this.percentage = input} type="number"/>
                    </div>
                    <div className="campaigns__input-container">
                        <button type="submit">Crear Campaña</button>
                    </div>
                </form>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Proyecto/Empresa</th>
                            <th>Status</th>
                            <th>Porcentaje en Venta</th>
                            <th>Procentaje Vendido</th>
                            <th>Objetivo</th>
                            <th>Recolectado</th>
                            <th>Ver más</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.validator(this.state.campaigns)}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Campaigns;